<template>
  <div class="agreement wrapper">
    <p class="title-top" />
    <!--    eslint-disable vue/no-v-html-->
    <div
      class="div-partition"
      v-html="text"
    />
  </div>
</template>

<script>
import { formatFirstUpperCase } from '@/utils/index.js'

export default {
  metaInfo: {
    title: 'PrivacyPolicy',
    link: []
  },
  data () {
    return {
      text: `
      
      <br>
       <br>
       <h3>Introduction</h3>
        <br>
         <br>
         These conditions govern the use of the goods and services offered by Hololink– in particular, a service which provides access to a personal computer. And the service is subject to special conditions, the user's personal computer must have an NVIDIA graphics card.
         <br>
        <br>
      These Conditions must be accepted to access the Services.
        <br>
         <br>
           Please read these Conditions carefully. By using the Services, you agree to be bound by these Conditions and all other terms and policies incorporated herein by reference. IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE OUR SERVICES.
        <br>
         <br>
         <h3>Internet Access</h3>
         
        <br>
         <br>
           Access to the Services, from both the Terminal and from Third-Party Terminals, means that the User has a working Internet connection. This connection is not included in the Services offered by Hololink and costs are the User’s responsibility. The quality of the Services also depends on the quality of this connection (for example, in terms of stability, speed, bandwidth, response time, etc.).
        <br>
         <br>
         The user is responsible for ensuring the quality of his or her Internet connection . And the user is informed that Hololink may transfer large quantities of data when using the service to play video games
          <br>
         <br>
         Hololink cannot be held liable for interruptions or alterations to its Services as a result of interruptions or alterations to the user's Internet access. No compensation will be granted to the user if his or her access to the Services has been interrupted or altered as a result of an Internet access problem.
        
          <br>
         <br>Lastly, the User is informed and acknowledges that for optimal functioning of the Services, it is strongly recommended to connect the Terminal or Third-Party Terminals to a local Internet service using an cable, rather than using WiFi or mobile Internet connections. 
          <br>
         <br>
         <h3> Personal data</h3>
        
          <br>
         <br>
         In addition to the Data saved on the users’ personal computer, Hololink is required to collect certain personal data on the users.
         <br>
         <br>
        The processing of the users’ personal data by Hololink is indicated in our Privacy Policy. By his or her acceptance of these Conditions, the users also adheres to the Privacy Policy in all its provisions.
         <br>
         <br>
        The personal data that the users sends to/via his or her personal computer is his or her sole responsibility. The users are the custodian of these data and are solely responsible for the processing of said data. For example, the User will be solely liable for transferring personal data to his or her personal computer Under no circumstances may Hololink be held liable, or be deemed responsible for processing such data.

         `,
      project: ' Netboom Game +'
    }
  },
  created () {
    const flavor = this.$route.query.flavor || ' Netboom Game +'
    this.project = formatFirstUpperCase(flavor)
  }
}
</script>

<style lang="less">
@import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
.wrapper {
  padding: 0 5%;
  background: #F0F0F0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  min-height: 100%;
  box-sizing: border-box;
}
</style>
